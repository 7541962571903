import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Badge } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import { useIntl, FormattedMessage } from 'react-intl'

import moment from 'moment'

import ChartCard from 'components/card/ChartCard'
import ChartColsCard from 'components/card/ChartColsCard'
import BarChart from 'components/charts/BarChart'
import RadarChart from 'components/charts/RadarChart'
import DoughnutChart from 'components/charts/DoughnutChart'

import EventsService from 'services/EventsService'

import EventStatsDataSet from 'helpers/EventStatsDataSet.helper'
import { AlertError, AlertLoading } from 'helpers/AlertHelper'

const Dashboard = () => {
    const intl = useIntl()

    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const [events, setEvents] = useState([])

    const [eventLoaded, setEventLoaded] = useState(null)
    const Event = useRef(new EventStatsDataSet()).current

    const [totals, setTotals] = useState({
        events: 0,
        groups: 0,
        entities: 0
    })

    const getEvents = async () => {
        await EventsService.getActiveEvents().then(
            response => {
                setEvents(response.data.events)
                calculateTotals(response.data.events)
                setIsLoaded(true)
            },
            error => {
                setError(error)
                setIsLoaded(false)
            }
        )
    }

    const getEventsStats = async (eventId) => {
        await EventsService.getEventStats(eventId).then(
            response => {
                Event.setData({ eventId: eventId, data: response.data.eventStats })
                setEventLoaded(eventId)
            },
            error => {
                setError(error)
                setEventLoaded(null)
            }
        )
    }

    const handleAccordionSelect = (eventId) => {
        if (eventId) {
            setEventLoaded(null)
            getEventsStats(eventId)
        }
    }

    const calculateTotals = (data) => {
        let totalGroups = 0
        let entities = []

        data.forEach((event) => {
            totalGroups += event.groups.length

            event.groups.forEach((group) => {
                entities = entities.concat(group.entitiesIds.map((entityId) => ({ id: entityId })));
            })
        })

        const uniqueEntities = [...new Set(entities.map((entity) => entity.id))];
        setTotals(
            {
                events: data.length,
                groups: totalGroups,
                entities: uniqueEntities.length
            }
        )
    }

    useEffect(() => {
        if (!isLoaded)
            getEvents()
    }, [])

    const datasetsTasksStatus = () => {
        const labels = [
            intl.formatMessage({ id: "tasksStatusPending", defaultMessage: "Pending" }),
            intl.formatMessage({ id: "tasksStatusInProgress", defaultMessage: "In progress" }),
            intl.formatMessage({ id: "tasksStatusFinished", defaultMessage: "Finished" }),
            intl.formatMessage({ id: "tasksStatusCancelled", defaultMessage: "Cancelled" })
        ]
        return { labels, datasets: Event.getTasksStatusDataset() }
    }

    const datasetsTasksResults = () => {        
        const labels = [
            intl.formatMessage({ id: "tasksResultGreen", defaultMessage: "Green" }),
            intl.formatMessage({ id: "tasksResultYellow", defaultMessage: "Yellow" }),
            intl.formatMessage({ id: "tasksResultRed", defaultMessage: "Red" }),
            intl.formatMessage({ id: "tasksResultBlack", defaultMessage: "Black" }),
            intl.formatMessage({ id: "tasksResultNone", defaultMessage: "None" })
        ]
        return { labels, datasets: Event.getTasksResultDataset() }
    }

    const datasetsRadar = () => {
        const labels = [
            intl.formatMessage({ id: "groups", defaultMessage: "Groups" }),
            intl.formatMessage({ id: "entities", defaultMessage: "Entities" }),
            intl.formatMessage({ id: "users", defaultMessage: "Users" }),
            intl.formatMessage({ id: "statsTasks", defaultMessage: "Tasks" }),
            intl.formatMessage({ id: "statsInspections", defaultMessage: "Inspections" })
        ]
        return { labels, datasets: Event.getRadarDataset() }
    }

    const datasetsGroupsTasks = () => {
        const labels = Event.groupLabels

        const datasets = [
            Event.getGroupTasksDataset('totalStatus_CANCELLED', intl.formatMessage({ id: "tasksStatusCancelled", defaultMessage: "Cancelled" }), 'lightslategray'),
            Event.getGroupTasksDataset('totalStatus_PENDING', intl.formatMessage({ id: "tasksStatusPending", defaultMessage: "Pending" }), 'orange'),
            Event.getGroupTasksDataset('totalStatus_IN_PROGRESS', intl.formatMessage({ id: "tasksStatusInProgress", defaultMessage: "In progress" }), 'aqua'),
            Event.getGroupTasksDataset('totalStatus_COMPLETED', intl.formatMessage({ id: "tasksStatusFinished", defaultMessage: "Finished" }), 'blue'),
            Event.getGroupTasksDataset('totalResult_GREEN', intl.formatMessage({ id: "tasksResultGreen", defaultMessage: "Green" }), 'green'),
            Event.getGroupTasksDataset('totalResult_YELLOW', intl.formatMessage({ id: "tasksResultYellow", defaultMessage: "Yellow" }), 'yellow'),
            Event.getGroupTasksDataset('totalResult_RED', intl.formatMessage({ id: "tasksResultRed", defaultMessage: "Red" }), 'red'),
            Event.getGroupTasksDataset('totalResult_BLACK', intl.formatMessage({ id: "tasksResultBlack", defaultMessage: "Black" }), 'black'),
            Event.getGroupTasksDataset('totalResult_NONE', intl.formatMessage({ id: "tasksResultNone", defaultMessage: "None" }), 'thistle'),
        ]

        return { labels, datasets }
    }

    const datasetsGroupsUsers = () => {
        const labels = Event.groupLabels

        const datasets = [
            Event.getGroupUsersDataset('usersIds', intl.formatMessage({ id: "users", defaultMessage: "Users" }), '#f9cf35'),
            Event.getGroupUsersDataset('entitiesIds', intl.formatMessage({ id: "entities", defaultMessage: "Entities" }), '#d1aa29'),
        ]

        return { labels, datasets }
    }

    const groupContentRow1 = () => {
        return (
            [
                [{
                    icon: <i className="bi bi-diagram-3"></i>,
                    title: <FormattedMessage id="groups" defaultMessage="Groups" />,
                    text: Event.totalGroups,
                    numberAlign: 'center'
                },
                {
                    icon: <i className="bi bi-briefcase-fill"></i>,
                    title: <FormattedMessage id="entities" defaultMessage="Entities" />,
                    text: Event.totalEntities,
                    numberAlign: 'center'
                },
                {
                    icon: <i className="bi bi-people-fill"></i>,
                    title: <FormattedMessage id="users" defaultMessage="Users" />,
                    text: Event.totalUsers,
                    numberAlign: 'center'
                }]
            ]
        )
    }

    const groupContentRow2 = () => {
        return (
            [
                [{
                    icon: <i className="bi bi-clipboard2-fill"></i>,
                    title: <FormattedMessage id="statsTotalTasks" defaultMessage="Total tasks" />,
                    text: Event.totalTasks,
                    numberAlign: 'center'
                },
                {
                    icon: <i className="bi bi-house-fill"></i>,
                    title: <FormattedMessage id="statsInspections" defaultMessage="Inspections" />,
                    text: Event.totalResults,
                    numberAlign: 'center'
                },
                {
                    icon: <i className="bi bi-hourglass-split"></i>,
                    title: <FormattedMessage id="statsLastUpdated" defaultMessage="Last updated" />,
                    text: <small>{moment(Event.lastUpdated).fromNow(true)}</small>,
                    numberAlign: 'center'
                }]
            ]
        )
    }

    const renderEvents = () => (
        <Card bg="light">
            <Card.Body className="p-1">
                <Accordion defaultActiveKey="0" flush onSelect={handleAccordionSelect}>
                    {events.map((event) => {
                        return (
                            <Accordion.Item eventKey={event._id} key={'stats-event-accordion-' + event._id}>
                                <Accordion.Header>
                                    <h5>
                                        <Badge bg='danger'><i className="bi bi-calendar-event"></i>&nbsp;{moment(event.createdAt).fromNow()}</Badge>&nbsp;
                                        {event.hazardType} - {event.name}
                                    </h5>
                                </Accordion.Header>
                                <Accordion.Body className='p-1 bg-light'>
                                    <p><i className="bi bi-card-text"></i> {event.description}</p>
                                    {eventLoaded && eventLoaded === event._id &&
                                        <>
                                            <Row>
                                                <Col xs={6}>
                                                    <ChartColsCard
                                                        //key={'event-accord-group-stats-' + event._id}
                                                        content={groupContentRow1()}
                                                        border={'light'}
                                                    //className={'mb-2'}
                                                    />
                                                    <ChartColsCard
                                                        //key={'event-accord-group-stats-' + event._id}
                                                        content={groupContentRow2()}
                                                        border={'light'}
                                                    //className={'mb-2'}
                                                    />
                                                </Col>
                                                <Col xs={2}>
                                                    <DoughnutChart
                                                        data={datasetsTasksStatus()}
                                                        title={intl.formatMessage({ id: "statsTasksStates", defaultMessage: "Tasks states" })}
                                                    />
                                                </Col>
                                                <Col xs={2}>
                                                    <DoughnutChart
                                                        data={datasetsTasksResults()}
                                                        title={intl.formatMessage({ id: "statsInspections", defaultMessage: "Inspections" })}
                                                    />
                                                </Col>
                                                <Col xs={2}>
                                                    <RadarChart
                                                        data={datasetsRadar()}
                                                        title={intl.formatMessage({ id: "statsRadar", defaultMessage: "Radar" })}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <BarChart
                                                        data={datasetsGroupsTasks()}
                                                        title={intl.formatMessage({ id: "statsGroupTasksStatesResults", defaultMessage: "Groups tasks states & results" })}
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <BarChart
                                                        data={datasetsGroupsUsers()}
                                                        title={intl.formatMessage({ id: "statsGroupUsersAndEntities", defaultMessage: "Groups users & entities" })}
                                                    />

                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </Card.Body>
        </Card>
    )

    if (error) {
        return (
            <AlertError error={error} />
        )
    } else if (!isLoaded) {
        return (
            <AlertLoading isLoaded={isLoaded} />
        )
    }

    return (
        <Container fluid>
            <Row className='p-2'>
                <Col xs={12} md={6} lg={4}>
                    <ChartCard
                        footer={<FormattedMessage id="events" defaultMessage="Events" />}
                        icon={<i className="bi bi-calendar-event"></i>}
                        title={<FormattedMessage id="statsActiveEvents" defaultMessage="Active events" />}
                        text={totals.events}
                    />
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <ChartCard
                        footer={<FormattedMessage id="groups" defaultMessage="Groups" />}
                        icon={<i className="bi bi-diagram-3"></i>}
                        title={<FormattedMessage id="statsInterventionGroups" defaultMessage="Intervention groups" />}
                        text={totals.groups}
                    />
                </Col>
                <Col xs={12} lg={4}>
                    <ChartCard
                        footer={<FormattedMessage id="entities" defaultMessage="Entitites" />}
                        icon={<i className="bi bi-briefcase"></i>}
                        title={<FormattedMessage id="statsEntities" defaultMessage="Entities & Organizations" />}
                        text={totals.entities}
                    />
                </Col>
            </Row>
            <Row className='p-2'>
                <Col xs={12} md={12} lg={12}>
                    {renderEvents()}
                </Col>
            </Row>
        </Container>
    )
}

export default Dashboard